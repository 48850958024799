.dates-range {
    width: 300px;
    height: 48px;
    padding-left: 43px;
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-position: 9px center;
    background-size: 24px auto;
    background-image: url('../../../assets/icon/calendar.png');
    input {
        font-family: var(--regular-font);
        font-size: 15px;
        color: var(--darkblue-color);
        &::placeholder {
            font-family: var(--regular-font);
            font-size: 15px;
            color: var(--gray-color);
        }
    }
    .ant-picker{
        border-color: #AFC1C2 !important;
        border-radius: 8px;
        box-shadow: none !important;
        .ant-picker-suffix{
            display: none !important;
        }
    }
    .ant-picker-active-bar, .ant-picker-range-separator{
        display: none !important;
    }
    .ant-picker-suffix span {
        opacity: 0;
    }
}