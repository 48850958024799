.waterfall-wrp {
    padding: 10px;
    box-sizing: border-box;
    #main {
        position: relative;
    }
    .splash-wrp {
        float: left;
        width: 50%;
        box-sizing: border-box;
        transition: all .4s;
        .splash {
            padding: 6px;
            box-sizing: border-box;
            position: relative;
            transition: all .4s;
        }
        img {
            width: 100%;
            height: auto;
        }
        /*button {
            position: absolute;
            left: 50px;
            top: 50px;
        }*/
    }
}