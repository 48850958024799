.complicated-wrp {
  .ant-modal-close {
    display: none !important;
  }
  .ant-modal-content {
    background-color: #ffffff;
    background-clip: padding-box;
    border-radius: 18px;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
      0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    pointer-events: auto;
    padding: 40px;
  }
  .ant-modal-footer {
    display: none !important;
  }
  .tit-area {
    font-family: var(--regular-font);
    font-size: 24px;
    color: #483778;
    line-height: 36px;
    box-sizing: border-box;
    padding-right: 40px;
    position: relative;
    padding-bottom: 25px;
    margin-bottom: 25px;
    border-bottom: 1px solid #f1e6f6;
    .close {
      width: 36px;
      height: 36px;
      position: absolute;
      right: 0;
      top: 0;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      background-image: url("../../../assets/icon/c.png");
      cursor: pointer;
    }
  }
}
