.dialog-wrp {
    /*.ant-modal-close {
        display: none !important;
    }
    .ant-modal-content {
        border-radius: 18px !important;
        padding: 40px !important;
    }
    .ant-modal-footer {
        display: none !important;
    }*/
    background-color: #ffffff;
    background-clip: padding-box;
    border-radius: 18px;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    pointer-events: auto;
    padding: 40px;
    .tit-area {
        font-family: var(--regular-font);
        font-size: 24px;
        color: #483778;
        line-height: 36px;
        box-sizing: border-box;
        padding-right: 40px;
        position: relative;
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid #F1E6F6;
        .close {
            width: 36px;
            height: 36px;
            position: absolute;
            right: 0;
            top: 0;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
            background-image: url('../../../assets/icon/c.png');
            cursor: pointer;
        }
    }

}