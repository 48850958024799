.jump-wrp {
    text-align: right;
    margin-top: 30px;
    .first-button, .last-button, .ant-pagination {
        float: right;
    }
    .first-button, .last-button {
        line-height: 30px;
        font-family: var(--regular-font);
        font-size: 14px;
        color: #483778;
        cursor: pointer;
        &.disable {
            color: #A39BBC;
            cursor: not-allowed;
        }
    }
    .first-button {
        margin-right: 10px;
    }
    .last-button {
        margin-left: 10px;
    }
}