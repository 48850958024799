.upload-wrp {
  width: 120px;
  height: 120px;
  border: 1px solid #f1e6f6;
  border-radius: 50%;
  .ico-uploader {
    .ant-upload {
      width: 120px !important;
      height: 120px !important;
      border-radius: 50% !important;
      border: none !important;
      margin-inline-end: 0 !important;
      margin-bottom: 0 !important;
      background-color: transparent !important;
      .ico {
        width: 32px;
        height: 32px;
        background: var(--icon-bgd);
        background-size: contain;
        background-image: url("../../../assets/icon/add.png");
      }
    }
  }

  .ico-uploading {
    line-height: 120px;
    text-align: center;
    font-size: 20px;
  }
  .ico-uplaoded {
    width: 100%;
    height: 120px;
    position: relative;
    border-radius: 50%;
    .img {
      width: 100%;
      height: 120px;
      background: var(--icon-bgd);
      background-size: cover;
      border-radius: 50%;
      overflow: hidden;
    }
    .del-ico {
      width: 24px;
      height: 24px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      background-color: #fff;
      border-radius: 50%;
      background-image: url("../../../assets/icon/y.png");
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
    }
  }
  &.rectangular {
    border-radius: 8px !important;
    .ico-uploader {
      .ant-upload {
        border-radius: 8px !important;
      }
    }
    .ico-uplaoded {
      .img {
        border-radius: 8px !important;
      }
    }
  }
}
.cropper_box {
  max-height: 500px;
  img {
    width: 100%;
    height: 100%;
  }
}
.cropper-btns {
  text-align: right;
  margin-top: 20px;
  .cancel-btn {
    width: 100px;
    height: 40px;
    background-color: #f0f0f0 !important;
    border-color: #f0f0f0 !important;
    border-radius: 8px;
    font-family: var(--regular-font);
    color: #483778 !important;
    font-size: 14px;
    margin-right: 15px;
  }
  .confirm-btn {
    width: 100px;
    height: 40px;
    background-color: #483778 !important;
    border-color: #483778 !important;
    border-radius: 8px;
    font-family: var(--regular-font);
    color: #ffffff !important;
    font-size: 14px;
  }
}
