@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Regular.ttf");
}
.clear::after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  clear: both;
}
html {
  word-break: break-word;
}
ul,
h1,
h2,
h3,
h4,
h5,
p {
  padding: 0;
  margin: 0;
}
li {
  list-style: none;
}
.scrollable {
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: var(--scroll-barcolor);
    border-radius: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--scroll-thumbcolor);
    border-radius: 2px;
  }
}
:root {
  --regular-font: "Poppins";
  --pure-black-color: #000000;
  --pure-white-color: #ffffff;
  --gray-color: #a39ab9;
  --darkblue-color: #483778;
  --warn-color: #db3939;
  --pure-white-bgdcolor: #ffffff;
  --pure-black-bdgcolor: #000000;
  --transparent-bgdcolor: transparent;
  --pure-black-bordercolor: #000000;
  --scroll-barcolor: #f8f8fa;
  --scroll-thumbcolor: #e4e4e4;
  --icon-bgd: center center no-repeat;
}
.normal-form {
  .image-wrp {
    padding-bottom: 30px;
    position: relative;
  }
  .ant-form-item-label {
    padding-bottom: 15px !important;
    font-family: var(--regular-font);
    font-weight: 500;
    font-size: 16px;
    color: #483778;
    line-height: 19px;
  }
  .ant-form-item .ant-form-item-label > label {
    color: #483778 !important;
    font-size: 16px !important;
    line-height: 19px !important;
  }
  .ant-form-item-required,
  .label-customize {
    font-family: var(--regular-font);
    font-weight: 500;
    font-size: 16px;
    color: #483778;
    line-height: 16px;
  }
  .label-customize span {
    font-size: 14px;
  }
  .ant-form-item-required::before {
    display: none !important;
  }
  .ant-input {
    height: 56px;
    background-color: var(--transparent-bgdcolor);
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    box-shadow: none;
    border-color: #f1e6f6;
    font-size: 14px;
    font-family: var(--regular-font);
    color: #091936;

    &::placeholder {
      font-size: 14px;
      font-family: var(--regular-font);
      color: #aeaeae;
    }
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: #f1e6f6;
  }
  .ant-input-affix-wrapper {
    border-color: #f1e6f6;
  }
  .ant-input-textarea .ant-input {
    height: 120px;
    padding: 11px !important;
  }
  :where(.css-dev-only-do-not-override-1me4733).ant-form-item {
    line-height: 14px !important;
  }
  .explain-error {
    font-size: 12px;
    font-family: var(--regular-font);
    color: #ff4d4f;
    padding-top: 3px;
    position: absolute;
    left: 0;
    bottom: 0;
  }
  .ant-form-item-explain-error {
    font-size: 12px;
    font-family: var(--regular-font);
    // color: #7F73A0;
  }
  .ant-input-affix-wrapper {
    padding: 0 11px !important;
  }
  .ant-form-item-control-input {
    // margin-bottom: 8px;
  }
  .eye-open {
    display: inline-block;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    background-image: url("../icon/eye-open.png");
    position: relative;
    top: 2px;
    cursor: pointer;
  }
  .eye-close {
    display: inline-block;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    background-image: url("../icon/eye-close.png");
    position: relative;
    top: 2px;
    cursor: pointer;
  }
  .mar-btm-0 {
    margin-bottom: 0 !important;
    .ant-form-item-control-input {
      margin-bottom: 0 !important;
    }
  }
  .choose-simple {
    .ant-select-selector {
      height: 48px !important;
      border-radius: 8px !important;
      // border: 1px solid #F1E6F6 !important;
      padding-left: 10px !important;
    }
    .ant-select-selection-item {
      line-height: 45px !important;
      font-family: var(--regular-font);
      font-size: 15px;
      color: var(--darkblue-color) !important;
    }
    .ant-select-selection-placeholder {
      line-height: 45px !important;
      font-family: var(--regular-font);
      font-size: 15px;
    }
    .ant-select-arrow {
      margin-top: -10px !important;
      width: 20px;
      height: 20px;
      background-repeat: no-repeat;
      background-size: 20px auto;
      background-position: center top;
      background-image: url("../icon/arrow-simple.png");
      transition: all 0.3s;
      span {
        opacity: 0;
      }
    }
    &.ant-select-open {
      .ant-select-arrow {
        background-position: center 0px;
        transform: rotate(180deg);
      }
    }
  }
  .ant-input-show-count-suffix,
  .ant-input-data-count {
    font-family: var(--regular-font);
    font-size: 16px !important;
  }
  .ant-input-data-count {
    margin-bottom: 0 !important;
    position: relative;
    bottom: 24px;
    right: 10px;
  }
  .ant-form-item {
    margin-bottom: 20px !important;
    &.mar-btm-6 {
      margin-bottom: 6px !important;
    }
  }
}
.normal-mobile-form {
  .image-wrp {
    padding-bottom: 30px;
    position: relative;
  }
  .ant-form-item-label {
    padding-bottom: 15px !important;
    font-family: var(--regular-font);
    font-weight: 500;
    font-size: 14px;
    color: #483778;
    line-height: 19px;
  }
  .ant-form-item .ant-form-item-label > label {
    color: #483778 !important;
  }
  .ant-form-item-required,
  .label-customize {
    font-family: var(--regular-font);
    font-weight: 500;
    font-size: 14px;
    color: #483778;
    line-height: 16px;
  }
  .label-customize span {
    font-size: 14px;
  }
  .ant-form-item-required::before {
    display: none !important;
  }
  .ant-input {
    height: 40px;
    background-color: var(--transparent-bgdcolor);
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    box-shadow: none;
    border-color: #f1e6f6;
    font-size: 14px;
    font-family: var(--regular-font);
    color: #091936;

    &::placeholder {
      font-size: 14px;
      font-family: var(--regular-font);
      color: #aeaeae;
    }
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: #f1e6f6;
  }
  .ant-input-affix-wrapper {
    border-color: #f1e6f6;
  }
  :where(.css-dev-only-do-not-override-1me4733).ant-form-item {
    line-height: 14px !important;
  }
  .explain-error {
    font-size: 12px;
    font-family: var(--regular-font);
    color: #ff4d4f;
    padding-top: 3px;
    position: absolute;
    left: 0;
    bottom: 0;
  }
  .ant-form-item-explain-error {
    font-size: 12px;
    font-family: var(--regular-font);
    // color: #7F73A0;
  }
  .ant-input-affix-wrapper {
    padding: 0 11px !important;
  }
  .ant-form-item-control-input {
    // margin-bottom: 8px;
  }
  .eye-open {
    display: inline-block;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    background-image: url("../icon/eye-open.png");
    position: relative;
    top: 2px;
    cursor: pointer;
  }
  .eye-close {
    display: inline-block;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    background-image: url("../icon/eye-close.png");
    position: relative;
    top: 2px;
    cursor: pointer;
  }
  .mar-btm-0 {
    margin-bottom: 0 !important;
    .ant-form-item-control-input {
      margin-bottom: 0 !important;
    }
  }
  .ant-input-show-count-suffix,
  .ant-input-data-count {
    font-family: var(--regular-font);
    font-size: 16px !important;
  }
  .ant-input-data-count {
    margin-bottom: 0 !important;
    position: relative;
    bottom: 24px;
    right: 10px;
  }
  .ant-form-item {
    margin-bottom: 20px !important;
    &.mar-btm-6 {
      margin-bottom: 6px !important;
    }
  }
}
.codes-form {
  .ant-form-item-label {
    display: none !important;
  }
}
.ant-select-item-option-content {
  font-family: var(--regular-font);
}
.ant-picker-panels {
  font-family: var(--regular-font);
}
.ant-pagination-item a {
  font-family: var(--regular-font);
  font-size: 14px;
  color: #483778;
}
.ant-pagination-item-active {
  border-color: #f1e6f6 !important;
  background-color: #f1e6f6 !important;
  a {
    color: #483778 !important;
  }
}
.ant-pagination-prev button:hover,
.ant-pagination-next button:hover {
  background-color: transparent !important;
}
.max-limit {
  max-height: 500px;
  margin-bottom: 15px;
  overflow: auto;
}
@media screen and (max-height: 800px) {
  .max-limit {
    max-height: 350px;
  }
}
.save-button {
  width: 100%;
  height: 60px;
  background-color: #483778;
  border-radius: 8px;
  font-family: var(--regular-font);
  font-size: 20px;
  color: #ffffff !important;
  outline: none;
  box-shadow: none;
  border-color: #483778;
  opacity: 0.6;
  cursor: not-allowed;
  &.lighten {
    opacity: 1;
    cursor: pointer;
  }
  &.disable {
    cursor: not-allowed;
  }
  &:disabled {
    cursor: not-allowed;
    background-color: #483778 !important;
  }
}
.form-label {
  font-family: var(--regular-font);
  font-weight: 500;
  font-size: 16px;
  color: #483778;
  line-height: 20px;
  margin-bottom: 10px;
}
.form-warn {
  font-family: var(--regular-font);
  font-weight: 500;
  font-size: 16px;
  color: #7d6ea7;
  line-height: 20px;
  margin-bottom: 10px;
  &.mar-btm-15 {
    margin-bottom: 15px;
  }
}
.img-areas {
  position: relative;
}
.img-area {
  float: left;
  width: 120px;
  margin-bottom: 20px;
  margin-right: 50px;
  .label {
    margin-top: 20px;
    text-align: center;
    font-family: var(--regular-font);
    font-size: 14px;
    color: #483778;
    line-height: 16px;
  }
}
.limit-height .ant-form-item-control-input-content {
  height: 120px !important;
}
