.choose-customize {
    width: 160px;
    .ant-select-selector {
        height: 48px !important;
        border-radius: 8px !important;
        border: 1px solid #F1E6F6 !important;
        padding-left: 10px !important;
    }
    .ant-select-selection-item {
        line-height: 45px !important;
        font-family: var(--regular-font);
        font-size: 15px;
        color: var(--darkblue-color) !important;
    }
    .ant-select-arrow{
        background-repeat: no-repeat;
        background-size: 12px auto;
        background-position: center top;
        background-image: url('../../../assets/icon/arrow-select.png');
        transition: all 0.3s;
        span{
            opacity: 0;
        }
    }
    &.ant-select-open {
        .ant-select-arrow{
            background-position: center 0px;
            transform: rotate(180deg);
        }
    }
}