.simple-wrp {
    /*.ant-modal-close {
        display: none !important;
    }
    .ant-modal-content {
        border-radius: 18px !important;
        padding: 40px !important;
    }
    .ant-modal-footer {
        display: none !important;
    }*/
    .ant-modal-content {
        background-color: #ffffff;
        background-clip: padding-box;
        border-radius: 18px;
        box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
        pointer-events: auto;
        padding: 40px;
    }
    .ant-modal-footer {
        display: none !important;
    }
    .tit-area {
        font-family: var(--regular-font);
        font-size: 16px;
        color: #483778;
        line-height: 24px;
        margin-bottom: 50px;
        text-align: center;
        word-break: break-word;
    }
    .cancel-btn {
        width: 230px;
        height: 60px;
        background-color: #F0F0F0 !important;
        border-color: #F0F0F0 !important;
        border-radius: 8px;
        font-family: var(--regular-font);
        color: #483778 !important;
        font-size: 20px;
        margin-right: 20px;
    }
    .confirm-btn {
        width: 230px;
        height: 60px;
        background-color: #483778 !important;
        border-color: #483778 !important;
        border-radius: 8px;
        font-family: var(--regular-font);
        color: #ffffff !important;
        font-size: 20px;
    }
}