.goalReminder-wrp {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 2 * 50px);
  h1 {
    font-family: var(--regular-font);
    font-weight: 500;
    font-size: 32px;
    color: var(--darkblue-color);
    line-height: 38px;
    margin-bottom: 30px;
  }
  .tabs {
    margin-bottom: 30px;
    height: 60px;
    box-sizing: border-box;
    border-bottom: 3px solid #f1e6f6;
    .tab {
      float: left;
      width: 180px;
      font-family: var(--regular-font);
      font-weight: 500;
      font-size: 20px;
      color: var(--darkblue-color);
      line-height: 60px;
      text-align: center;
      position: relative;
      cursor: pointer;
      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 3px;
        background-color: #c8c3d7;
        position: absolute;
        left: 0;
        bottom: 0;
      }
      &.active {
        font-weight: bold;
        &::after {
          background-color: #483778;
        }
      }
    }
  }
  .search {
    display: flex;
    height: 48px;
    justify-content: space-between;
    .create-button {
      width: 160px;
      height: 48px;
      background: #483778 !important;
      border-radius: 8px;
      border: 1px solid #483778;
      font-family: var(--regular-font);
      font-size: 14px;
      color: #fff;
    }
  }
  .tips {
    font-family: var(--regular-font);
    font-weight: 400;
    font-size: 16px;
    color: #483778;
    line-height: 19px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .grid-wrp {
    flex: 1;
  }
  .gold-reminder {
    &-content-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
