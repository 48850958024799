.look-for-input {
    width: 240px;
    height: 48px;
    border-radius: 8px;
    border: 1px solid #F1E6F6;
    padding-left: 44px;
    background-repeat: no-repeat;
    background-position: 10px center;
    background-size: 24px auto;
    background-image: url('../../../assets/icon/look-for.png');
    font-family: var(--regular-font);
    font-size: 15px;
    color: var(--darkblue-color);
    &::placeholder {
        font-family: var(--regular-font);
        font-size: 15px;
        color: var(--gray-color);
    }
}